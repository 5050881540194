<template>
  <Row class="popup-add-object__info">
    <Column class="popup-add-object__info-column-icon">
      <Icon class="popup-add-object__info-icon"
            :height="'30px'"
            :width="'30px'"
            viewport="0 0 40 40"
            xlink="info"/>
    </Column>
    <Column class="popup-add-object__info-column-text">
      <slot/>
    </Column>
  </Row>
</template>

<script>
export default {
  name: 'Info',
};
</script>
